import { set, cloneDeep } from "lodash";
import * as moment from "moment";

import {
  SET_TITLE,
  SET_STATS,
  SET_ALERT,
  SET_ACTIVITY,
  REMOVE_ALERT,
  SET_POPIN,
  REMOVE_POPIN,
  SET_LOGGED,
  SET_PLAYER,
  REMOVE_PLAYER,
  ADD_APICALL,
  REMOVE_APICALL,
  SET_USER,
} from "./actions";

import { decryptDate } from "services/date";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_TITLE: {
      return {
        ...state,
        title: payload,
      };
    }
    case SET_STATS: {
      return {
        ...state,
        stats: payload,
      };
    }
    case SET_LOGGED: {
      return {
        ...state,
        logged: payload,
      };
    }
    case SET_ALERT: {
      const date = new Date();
      const newAlert = {
        label: payload.label,
        time: payload.time || decryptDate(date, "LTS"),
        timeEnd: decryptDate(moment(date).add(10, "seconds"), "LTS"),
        type: payload.type,
        target: payload.target,
      };
      return {
        ...state,
        alerts: [...state.alerts, newAlert],
      };
    }
    case REMOVE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter(({ time }) => time !== payload),
      };
    }
    case ADD_APICALL: {
      const newApiCallsSet = state.apiCalls;
      newApiCallsSet.add(payload);
      return {
        ...state,
        apiCalls: newApiCallsSet,
      };
    }
    case REMOVE_APICALL: {
      const newApiCallsSet = state.apiCalls;
      newApiCallsSet.delete(payload);
      return {
        ...state,
        apiCalls: newApiCallsSet,
      };
    }
    case SET_POPIN: {
      const newPopin = {
        title: payload.title,
        label: payload.label,
        secondaryLabel: payload.secondaryLabel,
        secondaryCallback: payload.secondaryCallback,
        primaryLabel: payload.primaryLabel,
        primaryCallback: payload.primaryCallback,
      };
      return {
        ...state,
        popin: newPopin,
      };
    }
    case REMOVE_POPIN: {
      return {
        ...state,
        popin: false,
      };
    }
    case SET_PLAYER: {
      const newPlayer = {
        type: payload.type,
        path: payload.path,
        title: payload.title,
        description: payload.description,
        dateStart: payload.dateStart,
        dateEnd: payload.dateEnd,
      };
      return {
        ...state,
        player: newPlayer,
      };
    }
    case REMOVE_PLAYER: {
      return {
        ...state,
        player: false,
      };
    }
    case SET_ACTIVITY: {
      if (payload.path.length !== 0) {
        const newActivity = cloneDeep(state.activity);
        set(newActivity, payload.path, payload.value);
        return {
          ...state,
          activity: newActivity,
        };
      }
      return {
        ...state,
        activity: payload.value,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
