export const SET_TITLE = "SET_TITLE";
export const SET_STATS = "SET_STATS";
export const SET_ALERT = "SET_ALERT";
export const SET_POPIN = "SET_POPIN";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_POPIN = "REMOVE_POPIN";
export const SET_LOGGED = "SET_LOGGED";
export const SET_PLAYER = "SET_PLAYER";
export const REMOVE_PLAYER = "REMOVE_PLAYER";
export const ADD_APICALL = "ADD_APICALL";
export const REMOVE_APICALL = "REMOVE_APICALL";
export const SET_USER = "SET_USER";
