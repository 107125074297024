import React from "react";
import { Link } from "react-router-dom";
import "./Login.scss";
import Input from "components/Form/Input";
import Alert from "components/Alert";
import logo from "assets/logo-small.webp";

export const Login = ({ email, setEmail, password, setPassword, login }) => (
  <div className="login-page">
    <div className="container shadow--dark">
      <img src={logo} alt="Logo Dedomia" className="login__logo" />
      <h2 className="subtitle">Connexion</h2>
      <form onSubmit={(e) => login(e)}>
        {document.location.href.split("=")[1] && <Alert type="info" label="Votre mot de passe a bien été changé" key="passwordChanged" />}
        <Input type="email" label="Adresse email" placeholder="contact@dedomia.fr" required value={email} onChange={(e) => setEmail(e)} />
        <Input type="password" label="Mot de passe" required value={password} onChange={(e) => setPassword(e)} />
        <Link to={`/login/forgot?email=${email}`}>Mot de passe oublié</Link>
        <div className="btn-container">
          <button className="btn btn--primary" type="submit">
            Se connecter
          </button>
        </div>
      </form>
    </div>
  </div>
);
