import React, { useState, useEffect } from "react";
import { Information } from "./Information";
import { useAppContext } from "context/AppContext";
import { changePasswordApi } from "services/api";


const EnhancedInformation = (props) => {
  const { context } = useAppContext();
  const [new_password, setPassword] = useState("");

  const changePassword = async () => {
    const res = await changePasswordApi({new_password})
  };

  return <Information {...props} user={context.user} changePassword={changePassword} setPassword={setPassword} />;
};

export default EnhancedInformation;