import React, { useState, useEffect } from 'react';
import { DynamicForm } from "./DynamicForm";
import { FormContext } from "context/FormContext";
import { useAppContext } from "context/AppContext";
import Axios from "axios";

function apiCall(path, data) {
  const config = {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        Authorization: sessionStorage.getItem("token"),
      }
  };  
  let actionData = Axios({
      method: 'post',
      url: path,
      data: data,
      headers: config.headers,
    });
};

const EnhancedDynamicForm = (props) => {
  const [elements, setElements] = useState(null);
  useEffect(() => {
    setElements(props.popupText[0])
  }, []);

  const { fields, page_label } = elements ?? {};
  const { dispatch } = useAppContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    const post_data = {}
    elements.fields.forEach(field => {
      post_data[field['field_id']] = field['field_value']
    });
    apiCall(elements['page_target'],  JSON.stringify(post_data))
    props.closePopup()
    dispatch({
      type: "SET_ALERT",
      payload: {
        label: "action.alert.effectuée",
        type: "success",
        target: /product/,
      },
    });

  }

  const handleChange = (id, event) => {
    const newElements = { ...elements }
    newElements.fields.forEach(field => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        switch (field_type) {
          case 'checkbox':
            field['field_value'] = event.target.checked;
            break;
          default:
            field['field_value'] = event.target.value;
            break;
        }
      };
      setElements(newElements)
    });
  }
  return (
    <FormContext.Provider value={{ handleChange }}>
      <div className="App container">
        <form>
          {fields ? fields.map((field, i) => <DynamicForm key={i} field={field} />) : null}
          <button type="submit" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Soumettre</button>
        </form>
      </div>
    </FormContext.Provider>
  )
};

export default EnhancedDynamicForm;
