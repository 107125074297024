import React from "react";

import "./Label.scss";

export const Label = ({ label, required, id }) => (
  <label htmlFor={id} className="input__label">
    {label}
    {required && <span className="input__label-required">*</span>}
  </label>
);
