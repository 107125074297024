import React from "react";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { ListGroup } from 'react-bootstrap';
import DynamicForm from 'components/DynamicForm';

import "./ActionList.scss";


export class ActionList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible : false,
            actionName :  "Action",
            popupText : 'Action effectué'
        };

        this.openPopup = this.openPopup.bind(this);
    }

/*     openPopup = (actionName, path, external_form) => {
        this.setState({
            actionName : actionName, 
            popupVisible : true,
        });
        if (external_form){

            this.setState({
                popupText : {__html: external_form}
            });
        }
        else {
            this.apiCall(path, 'post');
            this.setState({
                popupText : {__html: JSON.stringify('Action effectué')}
            });    
        }
    }   */
    openPopup = (actionName, path, external_form) => {
        this.setState({
            actionName : actionName, 
            popupVisible : true,
        });
        if (external_form){
            this.setState({
                popupText : JSON.parse(external_form)
            });    
        }
        else {
            this.apiCall(path, 'post');
            this.setState({
                popupText : 'Action effectué'
            });    
        }
    }    
    
    closePopup = () => this.setState({popupVisible : false})

    async apiCall(path, method){
        const config = {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Origin": "*",
              Authorization: sessionStorage.getItem("token"),
            },
        };  
        let actionData =  await Axios({
            method: method,
            url: path,
            headers: config.headers,
          });
    }

    render() {
        return (   
            <>
                <Modal show={this.state.popupVisible} onHide={this.closePopup}>
                    <Modal.Header closeButton><Modal.Title>{this.state.actionName.toString()}</Modal.Title></Modal.Header>
                    <Modal.Body>
                      <DynamicForm popupText={this.state.popupText} closePopup={this.closePopup}/>
                    </Modal.Body>
                </Modal>                                                 
                <ListGroup variant="flush">
                    {this.props.actions.map((action) => {
                        if(action.active) {
                            return <ListGroup.Item>- <a href="javascript:void(0);" onClick={() => {this.openPopup(action.name, action.path, action.external_form)}}>{action.name}</a></ListGroup.Item>
                        }
                        else {
                            return <ListGroup.Item>- <a class="btn-link disabled" href="#">{action.name}</a></ListGroup.Item>
                        };
                    })}
                </ListGroup>
            </>
        )
    }
}