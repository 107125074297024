import React from "react";
import Input from "components/Form/Input";

import "./Password.scss";

export const Password = ({ password, passwordStrength, setPassword, passwordVerif, setPasswordVerif, passwordOk, error, required }) => (
  <div className="password">
    <Input type="password" value={password} label="Mot de passe" placeholder="" error={error} onChange={(e) => setPassword(e)} required={required} />
    <div className="password__strength" style={{ width: `${(passwordStrength.strength / 11) * 100}%` }}></div>
    <ul className="password__policies">
      <p>Votre mot de passe doit contenir : </p>
      <li className={`password__policy ${passwordStrength.uppercase ? "password__policy--ok" : "password__policy--nok"}`}>une lettre majuscule</li>
      <li className={`password__policy ${passwordStrength.lowercase ? "password__policy--ok" : "password__policy--nok"}`}>une lettre miniscule</li>
      <li className={`password__policy ${passwordStrength.number ? "password__policy--ok" : "password__policy--nok"}`}>un chiffre</li>
      <li className={`password__policy ${passwordStrength.size ? "password__policy--ok" : "password__policy--nok"}`}>au moins 8 caractères</li>
    </ul>
    {passwordStrength.strength >= 11 && (
      <Input
        type="password"
        value={passwordVerif}
        label="Confirmation du mot de passe"
        error={password.length > 0 && passwordVerif.length > 0 && !passwordOk ? "Les mots de passes sont différents" : null}
        placeholder=""
        required={required}
        onChange={(e) => setPasswordVerif(e)}
      />
    )}
  </div>
);
