import React from "react";
import { Accordion, Card, Nav } from 'react-bootstrap';
import ActionList from 'components/ActionList'



import "./ExpendableList.scss";


export class ExpendableList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.products.length === 0) {
            return <div>Il n'y a pas de sous-produit à afficher</div>
        }
        return (
            <>
                <Accordion>
                    {this.props.products && this.props.products.map((product) => (
                        <Card>
                            <Accordion.Toggle as={Card.Header} class="smallHeader-card" eventKey="0">
                                <div class="AccordionTitle">
                                    <>
                                        <div class="AccordionTitleLine">
                                            ↳  {product.name}
                                        </div>
                                        <div class="AccordionTitleLineIcon">
                                            <span class="IconButton-label"><svg class="SvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></span>
                                        </div>
                                    </>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Accordion defaultActiveKey="1">
                                    <Card>
                                        <Card.Header>
                                            <Nav fill variant="tabs" defaultActiveKey="1">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1">Sous-éléments</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2">Actions</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="3">Détail</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <Card.Text>
                                                        <ExpendableList {...{"products" : product.subProducts}}/>
                                                    </Card.Text>
                                                </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <Card.Text>
                                                         <ActionList {...{"actions" : product.actions}} />
                                                     </Card.Text>
                                                </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <Card.Text>
                                                        {product.detail}
                                                    </Card.Text>
                                                </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </>
        )
    };
}
