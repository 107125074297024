import { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { conf } from "services/env";

export const callApi = async ({ method = "get", namespace, id, payload, search, contentType = false }) => {
  const apiUrl = conf.apiUrl;

  const config = {
    headers: {
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      Authorization: sessionStorage.getItem("token"),
    },
  };
  if (!config.headers.Authorization) {
    config.headers.Authorization = sessionStorage.getItem("token");
  }
  if (contentType) {
    config.headers["content-type"] = contentType;
  }
  const idPart = id ? `/${id}` : "";
  const searchPart = search ? `?${search}` : "";
  const path = `${apiUrl}api/${namespace}${idPart}${searchPart}`;
  return await Axios({
    method,
    url: path,
    data: payload,
    headers: config.headers,
  });
};

export const useApi = ({ params: passedParams, defaultValue }) => {  
  const [params, setParams] = useState(passedParams || false);
  const [data, setData] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    try {
      setIsLoading(true);
      const res = await callApi({ ...params });
      setData(res.data);
      if (res.status >= 200 && res.status < 300) {
        params.callback && params.callback(res.data);
      } else {
        throw res;
      }
    } catch (error) {
      setError(error);
      if (params.callbackFail) {
        params.callbackFail(error.response);
      } else {
        console.log({ ...error });
      }
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("user");
          document.location.href = "/login";
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    Boolean(params) && fetchData();
  }, [params, fetchData]);

  return [data, setData, setParams, isLoading, error];
};
