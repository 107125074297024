import React from 'react';
import "./Billing.scss";

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

export const Billing = ({ operations, documents}) => (
    <>
        <Breadcrumb id="breadcrumb">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Facturation</Breadcrumb.Item>
        </Breadcrumb>
        <Container fluid>
        <Row className="billing__headline">
            <Col> ↳ Dernières Opérations</Col>
        </Row>
        <Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th className="smallColumn">#</th>
                    <th className="smallColumn">Date</th>
                    <th className="smallColumn">Type</th>
                    <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {operations.map(({ Id, Date, Type, Message }) => (
                        <tr key={Id} >
                            <td className="smallColumn">{Id}</td>
                            <td className="smallColumn">{Date}</td>
                            <td className="smallColumn">{Type}</td>
                            <td>{Message}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>
        <Row className="billing__headline">
            <Col> ↳ Documents et Factures</Col>
        </Row>
        <Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th className="smallColumn" >#</th>
                    <th className="smallColumn">Date</th>
                    <th>Titre</th>
                    <th  className="smallColumn">Lien</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map(({ Id, Date, Tittle, Lien }) => (
                        <tr key={Id} >
                            <td className="smallColumn">{Id}</td>
                            <td className="smallColumn">{Date}</td>
                            <td>{Tittle}</td>
                            <td className="smallColumn"><a href={Lien}><Button variant="dark" className="smallButton">Télécharger</Button></a></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>
        </Container>
    </>
);
