import React from "react";
import { Product } from "./Product";

import { useApi } from "services/api/api.hook";

const EnhancedProduct = (props) => {
  const [listCompanies, setListCompanies] = useApi({ params: { method: "get", namespace: "company" }, defaultValue: [] });
  return <Product {...props} companies={listCompanies} />;
};

export default EnhancedProduct;