import React from "react";
import Input from "components/Form/Input";
import Password from "components/Password";
import logo from "assets/logo-small--white.webp";

export const LoginForgot = ({ email, setEmail, state, forgotHandler, reinitHandler, code, setCode, password, setPassword }) => (
  <div className="login-page">
    <div className="container shadow--dark">
      <img src={logo} alt="Logo Froggy Lab" className="login__logo" />
      <h2 className="subtitle">Mot de passe oublié</h2>
      <form>
        <Input type="email" value={email} label="Adresse email" placeholder="contact@froggyplay.fr" required onChange={(e) => setEmail(e)} />
        {state === "INIT" && (
          <>
            <p>
              En cliquant sur "J'ai oublié mon mot de passe", un email vous sera envoyé avec un code de confirmation. Votre mot de passe actuelle sera
              désactivé.
            </p>
            <div className="btn-container">
              <button className="btn btn--primary" type="submit" onClick={(e) => forgotHandler(e)}>
                J'ai oublié mon mot de passe
              </button>
            </div>
          </>
        )}
        {state === "CODE_ASKED" && (
          <div className="form--conditional">
            <p>Vous avez du recevoir un code de vérification par email. Si vous ne l'avez pas recu après 5 minutes, vous pouvez redemander un code.</p>
            <Input type="text" value={code} label="Code de vérification" placeholder="XXXXX" required onChange={(e) => setCode(e)} />
            <Password onChange={(e) => setPassword(e)} required />
            <div className="btn-container">
              <button className={`btn btn--primary ${!password && "btn--disabled"}`} type="submit" onClick={(e) => reinitHandler(e)}>
                Changer mon mot de passe
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  </div>
);
