import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { requestResetPassword, resetPassword } from "services/api";
import { LoginForgot } from "./LoginForgot";

const EnhancedLoginForgot = (props) => {
  const [email, setEmail] = useState(document.location.href.split("=")[1]);
  const [state, setState] = useState("INIT");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const forgotHandler = (e) => {
    // CALL API
    e.preventDefault();
    requestResetPassword({ email });
    setState("CODE_ASKED");
  };

  const reinitHandler = (e) => {
    e.preventDefault();

    resetPassword({
      email: email,
      new_password: password,
      hash: code,
    });

    history.push("/login?mode=password_changed");
  };
  return (
    <LoginForgot
      {...props}
      email={email}
      setEmail={setEmail}
      state={state}
      forgotHandler={forgotHandler}
      reinitHandler={reinitHandler}
      code={code}
      setCode={setCode}
      password={password}
      setPassword={setPassword}
    />
  );
};

export default EnhancedLoginForgot;
