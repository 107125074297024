import React from "react";

import "./Alert.scss";

const typeToGlyphicon = type => {
  switch (type) {
    case "success":
      return "ok-sign";
    case "info":
      return "info-sign";
    case "error":
      return "remove-sign";
    default:
      return "asterisk";
  }
};

export const Alert = ({ time, label, type, close }) => (
  <div className={`alert alert--${type}`}>
    <i className={`alert__icon glyphicon glyphicon-${typeToGlyphicon(type)}`}></i>
    <span className="alert__time">{time}</span>
    <span className="alert__label">{label}</span>
    <i className="alert__close glyphicon glyphicon-remove" onClick={() => close(time)}></i>
  </div>
);
