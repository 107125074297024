import React from "react";
import Label from "components/Form/Label";
import Error from "components/Form/Error";

import "./Input.scss";

export const Input = ({ value, label, required, onChange, placeholder, error, id, type, mode, onFocus, onBlur, minDate, maxDate }) => (
  <div className={`input ${error && "input--error"} ${mode === "inline" ? "input-input--inline input--inline" : null}`}>
    <Label label={label} required={required} id={id} />
    <div className="input__field-container">
      <input
        type={type}
        id={id}
        className="input__field"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        min={minDate}
        max={maxDate}
      />
      <Error error={error} />
    </div>
  </div>
);
