import * as moment from "moment";
import "moment/locale/fr";

export const decryptDate = (date, format) => {
  moment.locale();
  return moment(date).format(format);
};

export const encryptDate = (date) => {
  return moment(date).toDate();
};

export const dateIsBetween = (rangeStart, rangeEnd, date) => {
  return moment(date).isBetween(rangeStart, rangeEnd || moment(date).add(1, "years"));
};

export const timeIsBeetween = (rangeStart, rangeEnd, time) => {
  var format = "hh:mm:ss";
  return time.isBetween(moment(rangeStart, format), moment(rangeEnd, format));
};

export const displaySeconds = (seconds) =>
  `${Math.trunc(seconds / 60)
    .toString()
    .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;

export const isInSchedulers = (date, schedulers) => {
  let isIn = true;
  if (schedulers.length > 0) {
    schedulers.forEach(({ dateRanges, weekdays, hourRanges }) => {
      if (!weekdays.map(({ id }) => id).includes(date.isoWeekday())) {
        isIn = false;
      }
      dateRanges.forEach(({ dateStart, dateEnd }) => {
        if (!dateIsBetween(dateStart, dateEnd, date)) {
          isIn = false;
        }
      });
      hourRanges.forEach(({ hourStart, hourEnd }) => {
        if (!timeIsBeetween(hourStart + ":00", hourEnd + ":00", date)) {
          isIn = false;
        }
      });
    });
  } else {
    isIn = false;
  }
  return isIn;
};

export const optionsDays = [
  { value: 1, label: "Lundi", byDay: "MO" },
  { value: 2, label: "Mardi", byDay: "TU" },
  { value: 3, label: "Mercredi", byDay: "WE" },
  { value: 4, label: "Jeudi", byDay: "TH" },
  { value: 5, label: "Vendredi", byDay: "FR" },
  { value: 6, label: "Samedi", byDay: "SA" },
  { value: 7, label: "Dimanche", byDay: "SU" },
];

export const momentToISO = (moment) => `${moment.format("YYYY")}${moment.format("MM")}${moment.format("DD")}T100000`;

export const weekdaysToByDay = (weekdays) => weekdays.map(({ id }) => optionsDays.find(({ value }) => value === id).byDay).join(",");

export const secondsToMMSS = (value) => {
  const seconds = Math.floor(value);
  const mm = Math.floor(seconds / 60);
  const ss = Math.floor(value % 60);
  return `${mm}min${ss}`;
};
