import React from "react";
import { Billing } from "./Billing";


const EnhancedBilling = (props) => {
    var operations = [{"Id": 1, "Date": "20/02/2021", "Type": "Installation", "Message": "TBD"}, {"Id": 2, "Date": "21/02/2021", "Type": "Livraison", "Message": "TBD"}]
    var documents = [{"Date": "21/02/2021", "Tittle": "Facture Téléphonique Fevrier 2021", "Link": "TBD"}]
  return <Billing {...props} operations={operations} documents={documents} />;
};

export default EnhancedBilling;