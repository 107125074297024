import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ExpendableList from 'components/ExpendableList'
import "./Product.scss";
import { Jumbotron } from 'react-bootstrap';

export const Product = ({ companies }) => (
    <>
        <Breadcrumb id="breadcrumb">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Produit</Breadcrumb.Item>
        </Breadcrumb>
        {companies.map(({ id, name, products }) => (
            <>
                <Jumbotron>
                    <p>{name}</p>
                    {<ExpendableList products={products} />}
                </Jumbotron>
            </>
        ))}
    </>
);
