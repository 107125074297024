import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
    .navbar {
        background-color: #222;
    }
    
    .navbar-brand, .navbar-nav .nav-link {
        color: #bdd;
    
        &:hover {
            color:white;
        }
    }
`;

/* export const NavigationBar = () => (
    <Styles>
        <Navbar expand="lg">
            <Navbar.Brand href="/">Dedomia</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Item><Nav.Link href="/product">Produits</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link href="/billing">Facturation</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link href="/information">Utilisateur</Nav.Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Styles>
) */

export const NavigationBar = () => (
    <Styles>
            <Navbar>
                <Navbar.Brand href="/">Dedomia</Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link href="product">Produits</Nav.Link>
                    <Nav.Link href="billing">Facturation</Nav.Link>
                    <Nav.Link href="information">Utilisateur</Nav.Link>
                </Nav>
            </Navbar>
    </Styles>   
)