import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Login } from "./Login";
import { useAppContext } from "context/AppContext";
import { loginApi } from "services/api";
import { useApi } from "services/api/api.hook";

const EnhancedLogin = (props) => {
  const { dispatch } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, , setParamsUser] = useApi({});
  const history = useHistory();

  const login = async (e) => {
    e.preventDefault();
    const res = await loginApi({ password, email });
    if (res.status === 200) {
      sessionStorage.setItem("token", 'Bearer'.concat(' ', res.data.Authorization));
      setParamsUser({
        namespace: "user/me",
        callback: (data) => {
          const user = {
            ...data,
          };
          sessionStorage.setItem("user", JSON.stringify(user));
          dispatch({
            type: "SET_USER",
            payload: user,
          });
          dispatch({ type: "SET_LOGGED", payload: 'Bearer'.concat(' ', res.data.Authorization)});
        },
      });
      history.push("/");
    } else {
      alert("Bad credentials");
    }
  };

  return <Login {...props} email={email} setEmail={setEmail} password={password} setPassword={setPassword} login={login} />;
};

export default EnhancedLogin;
