import Axios from "axios";
import { conf } from "services/env";

const url = conf.apiUrl;

export const loginApi = async (payload) => {
  try {
    const data = await Axios.post(`${url}auth/login`, payload);
    return data;
  } catch (error) {
    return error;
  }
};

export const requestResetPassword = async (payload) => {
  try {
    const data = await Axios.post(`${url}auth/request_reset_password`, payload);
    return data;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (payload) => {
  try {
    const data = await Axios.post(`${url}auth/reset_password`, payload);
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordApi = async (payload) => {
  const config = {
    headers: {
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      Authorization: sessionStorage.getItem("token"),
    },
  };
  if (!config.headers.Authorization) {
    config.headers.Authorization = sessionStorage.getItem("token");
  }
  config.headers["content-type"] = "application/json"
  try {
    const res = await Axios({
      method: 'POST',
      url: `${url}auth/change_password`,
      data: payload,
      headers: config.headers,
    });
    if (res.status >= 200 && res.status < 300) {
      alert('Mot de passe modifié');
    } else {
     alert('Un problème est survenu')
    }
  } catch (error) {
    return error;
  }
};


