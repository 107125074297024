import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import "./Home.scss";
import explication from "assets/explication.webp";


export const Home = () => (
    <>
        <Breadcrumb id="breadcrumb">
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
        <img src={explication} alt="Explication Dedomia" class="center" />
    </>
);