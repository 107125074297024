import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./Information.scss";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import profile_symbol from "assets/profile_symbol.png";



export const Information = ({ user, changePassword, setPassword }) => (
    <>
        <Breadcrumb id="breadcrumb">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Information</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
        <Tab eventKey="home" title="Informations Personelles">
            <Container fluid className="information__headline">
                <Row>
                    <Col md="auto">
                        <img src={profile_symbol} alt="Explication Dedomia" id="profil_pict"/>
                    </Col>
                    <Col>
                        <Container>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Prénom</InputGroup.Text>
                                        <FormControl defaultValue={user.firstName} readOnly />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Nom</InputGroup.Text>
                                        <FormControl defaultValue={user.lastName} readOnly />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                    <InputGroup.Text>@</InputGroup.Text>
                                        <FormControl defaultValue={user.email} readOnly/>
                                    </InputGroup>
                                </Col>
                                </Row>
                            <Row>    
                                <Col>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Nouveau mot de passe"
                                        aria-label="New Password"
                                        aria-describedby="basic-addon2"
                                        onChange={event => {setPassword(event.target.value)}}
                                    />
                                    <Button variant="secondary" id="changePassword_button" size="sm" onClick={changePassword}>
                                        Changer de mot de passe
                                    </Button>
                                </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Roles :</p> 
                        <ListGroup>
                            {user.roles.map((role) => {
                                return <ListGroup.Item>{role.name} de {role.company.name}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </Tab>
        <Tab eventKey="profile" title="Gestion Utilisateur">
            <Container fluid>
                    <Row className="information__headline">
                        <Col><p> ↳ Vous pourrez ici, créer et donner des accès à vos utilisateurs </p></Col>
                    </Row>
            </Container>
        </Tab>
        <Tab eventKey="contact" title="Contact" disabled>
            <Container fluid>
                <Row className="information__headline">
                    <Col> ↳ Zone desactivé pour le moment</Col>
                </Row>
            </Container>
        </Tab>
    </Tabs>
  </>
);
