import React from "react";
import { useAppContext } from "context/AppContext";
import { Alert } from "./Alert";

const EnhancedAlert = props => {
  const { dispatch } = useAppContext();
  const close = time => {
    dispatch({ type: "REMOVE_ALERT", payload: time });
  };
  return <Alert {...props} close={close} />;
};

export default EnhancedAlert;
