import React from "react";
import { useAppContext } from "context/AppContext";
import { ActionList } from "./ActionList";

const EnhancedActionList = actions => {
  const { dispatch } = useAppContext();
  if (actions['actions'] && actions['actions'].length){
    return <ActionList {...{"actions" : actions['actions']}} />;
  }
  else {
      return <p>Il n'y a pas d'action associée à ce produit</p>
  }

};

export default EnhancedActionList;
