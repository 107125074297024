import React, { useState, useEffect } from "react";
import { Password } from "./Password";

const calculPasswordStrength = (password) => {
  const policies = {
    strength: 0,
    lowercase: false,
    uppercase: false,
    number: false,
    size: false,
  };
  if (password.match(/[a-z]+/)) {
    policies.strength += 1;
    policies.lowercase = true;
  }
  if (password.match(/[A-Z]+/)) {
    policies.strength += 1;
    policies.uppercase = true;
  }
  if (password.match(/[0-9]+/)) {
    policies.strength += 1;
    policies.number = true;
  }
  if (password.length >= 8) {
    policies.size = true;
  }
  policies.strength += password.length <= 8 ? password.length : 8;
  return policies;
};

const EnhancedPassword = ({ onChange, error, required }) => {
  const [password, setPassword] = useState("");
  const [passwordVerif, setPasswordVerif] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordOk, setPasswordOk] = useState(false);

  useEffect(() => {
    if (passwordVerif === password) {
      setPasswordOk(true);
      onChange(password);
    } else {
      setPasswordOk(false);
      onChange(false);
    }
  }, [password, passwordVerif]);

  useEffect(() => {
    setPasswordStrength(calculPasswordStrength(password));
  }, [password]);

  return (
    <Password
      password={password}
      setPassword={setPassword}
      passwordStrength={passwordStrength}
      passwordVerif={passwordVerif}
      setPasswordVerif={setPasswordVerif}
      passwordOk={passwordOk}
      setPasswordOk={setPasswordOk}
      error={error}
      required={required}
    />
  );
};

export default EnhancedPassword;
