import React from "react";
import { useAppContext } from "context/AppContext";
import { ExpendableList } from "./ExpendableList";

const EnhancedExpendableList = products => {
  const { dispatch } = useAppContext();
  if (products['products'] && products['products'].length){
    return <ExpendableList {...{"products" : products['products']}}/>
  }
  else {
    return <div>Il n'y a pas de sous-produit à afficher</div>  
  };
};

export default EnhancedExpendableList;
