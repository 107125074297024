import React, { useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import "./App.scss";

import Home from 'pages/Home';
import Billing from 'pages/Billing';
import Product from 'pages/Product';
import Information from 'pages/Information';
import { NoMatch}  from 'pages/NoMatch';
import Login from "pages/Login";
import LoginForgot from "pages/LoginForgot";

import reducer from "context/reducer";
import AppContext from "context/AppContext";


import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';

const initialState = {
  title: "Accueil",
  apiCalls: new Set(),
  alerts: [],
};

const App = () => {
  const [context, dispatch] = useReducer(reducer, {
    logged: sessionStorage.getItem("token"),
    user: {
      ...JSON.parse(sessionStorage.getItem("user")),
    },
    ...initialState,
  });
  const value = { context, dispatch };
  return (
    <AppContext.Provider value={value}>
      <React.Fragment>
        {!context.logged && (
          <>
            <Router>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/forgot" component={LoginForgot} />
                <Route exact component={Login} />
              </Switch>
            </Router>
          </>
        )}
        {context.logged && (
          <>
            <NavigationBar /> 
            <Layout>  
              <Router>
                <Switch>
                  <Route exact path='/' exact component={Home} />
                  <Route path='/login' exact component={Login} />
                  <Route path='/product' exact component={Product} />
                  <Route path='/billing' exact component={Billing} />
                  <Route path='/information' exact component={Information} />
                  <Route component={NoMatch} />
                </Switch>
              </Router>
            </Layout>
          </>
        )}
      </React.Fragment>
    </AppContext.Provider>
  );
}

export default App;
